<template>
  <main>
    <HomeHero
      v-if="slide"
      :title="slide.title"
      :text="slide.text"
      :bold-count="slide.boldTitleWords"
      :image="slide.image"
      :link="slide.link"
    />

    <BlokkliProvider v-bind="blokkli">
      <Container class="mt-30 md:mt-50 lg:mt-60">
        <BlokkliField :list="node.paragraphs" name="field_paragraphs" />
      </Container>
      <Container>
        <BlokkliField
          :list="node.paragraphsFooter"
          field-list-type="footer"
          non-empty-class="is-footer"
          name="field_paragraphs_footer"
        />
      </Container>
    </BlokkliProvider>
  </main>
</template>

<script lang="ts" setup>
import { definePageMeta } from '#imports'
import {
  HomeBoxFragment,
  HomeSlideFragment,
  NodePageFragment,
} from '#graphql-operations'

definePageMeta({
  name: 'home',
  drupalRoute: true,
})

setBreadcrumbLinks()

provide('isFrontPage', true)

const nuxtRoute = useRoute()

// Get the data.
const { data: query } = await useAsyncData(nuxtRoute.path, () =>
  useCachedGraphqlQuery('route', {
    path: nuxtRoute.path,
  }).then((v) => v.data),
)

// Handles redirects and metatags.
const { entity: node } = await useDrupalRoute<NodePageFragment>(query.value)

const { data: homeConfig } = await useLazyAsyncData(() =>
  useCachedGraphqlQuery('home').then((v) => v.data.home),
)

const numbers = computed(() => {
  return {
    percentage: homeConfig.value?.numberPharmacies || 0,
    count: homeConfig.value?.numberOfPharmacists || 0,
  }
})

provide('home_number_highlights', numbers)

const slide = computed(() => {
  return homeConfig.value?.homeParagraphs?.find(
    (v) => v.__typename === 'ParagraphImageTeaser',
  ) as HomeSlideFragment | undefined
})

const boxes = computed(() => {
  return (homeConfig.value?.homeParagraphs?.filter(
    (v) => v.__typename === 'ParagraphMultiButtonBox',
  ) || []) as HomeBoxFragment[]
})

provide('home_teasers', boxes)

useRouteCache((c) => c.setCacheable().setMaxAge(600))
await renderPageDependencies()
const blokkli = buildBlokkliProps(node)
</script>
